import React, { useEffect } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"
import './gravidez.scss'
import ObstetraImg from '../images/ilustra-gravidez4.png'
import IlustraGravidez from '../images/ilustra-engravidar1.png'
import IlustraGravidezSecond from '../images/ilustra-engravidar2.png'
import IlustraGravidezThird from '../images/ilustra-engravidar3.png'
import IlustraGravidezFourth from '../images/ilustra-engravidar4.png'
import IlustraGravidezFifith from '../images/ilustra-engravidar5.png'
import trackEvent from "../analyticsHelpers"
import { FormEngravidar } from '../components/formEngravidar'

const Engravidar = () => {
  useEffect(() => {
    trackEvent("sitenovo-engravidar-visualizou-tela")
  }, [])

  const redirectButton = () => {
    trackEvent("sitenovo-engravidar-clicou-botao-agendarConsulta")
  }
  return (
    <Layout>
      <GatsbySeo
        title='Theia | Dicas para aumentar chances de engravidar'
        description='.'
        language='pt-br'
        canonical='https://blog.theia.com.br/engravidar'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/engravidar',
          title: 'Theia | Dicas para aumentar chances de engravidar',
          description: 'Aumente as chances de engravidar com dicas de nossa especialista',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Dicas para aumentar chances de engravidar'
        }, {
          name: 'dc:description',
          content: 'Aumente as chances de engravidar com dicas de nossa especialista'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Aumente as chances de engravidar com dicas de nossa especialista',
        },
         {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, Fisioterapeuta Pelvica, psicologos, fisioterapeuta pelvico, preparador fisico, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, dentista, atendimento odontológico na gestação, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="wrapper-section-row gravidez relative">
        <h1 className="text-titleMedium lg:text-titleExtraLarge font-medium text-darkPurpleColor item1 item1-engravidar">Dicas para aumentar chances de engravidar <span className="block text-textMedium lg:text-titleLarge">Conheça mais sobre ciclo menstrual e o que pode ajudar e o que pode prejudicar sua fertilidade</span></h1>
        <div className="item2 font-light lg:text-titleMedium text-textSmall text-darkGreyColor">
          <p>
            O desejo de engravidar traz muitas dúvidas para maioria dos casais, além da ansiedade para que isso ocorra rapidamente. Normalmente espera-se que 80% a 85% dos casais consigam engravidar espontaneamente num período de 6 a 12 meses. Porém, existem vários fatores que podem interferir nesse processo, sendo importante conhecê-los a fim de aumentar as chances de sucesso. Inicialmente, é essencial conhecer o funcionamento básico do ciclo menstrual para reconhecer o seu padrão e determinar o período fértil.
          </p>
          <h2 className="font-medium text-darkPurpleColor second-title">Ciclo menstrual</h2>
          <p>
            O ciclo menstrual é dividido em duas fases principais: a primeira (folicular) tem duração variável, iniciando no primeiro dia da menstruação e terminando na ovulação; a segunda (lútea) tem duração fixa de 14 dias e vai da ovulação até o dia anterior à menstruação. Assim, a ovulação sempre ocorre 14 dias antes da menstruação e em ciclos regulares é possível saber exatamente quando ela acontece. É considerado período fértil o período de 5 dias que antecede a ovulação, sendo possível identificá-lo de algumas formas: pela mudança no muco cervical, que fica mais elástico, transparente e escorregadio; pelo aumento da temperatura corporal basal (em repouso) em torno de 0,4 a 1°C logo após a ovulação e pelo teste de ovulação, que detecta o hormônio responsável pela ovulação (hormônio luteinizante - LH) na urina.
            Estudos indicam que a maior chance de sucesso acontece quando a relação sexual ocorre 1 ou 2 dias antes da ovulação, com taxas de gravidez em torno de 25% a 50%, dependendo da idade da mulher.
          </p>
          <p>É importante ressaltar que o atraso menstrual não significa necessariamente uma gravidez, pois muitas mulheres têm ciclos irregulares. Também não é incomum a presença de sangramentos no início da gravidez, que podem ser confundidos com menstruação. Portanto, é importante a confirmação com a dosagem de HCG (gonadotrofina coriônica humana) na urina ou no sangue.</p>
        </div>
        <div className="item3 engravidar">
          <img alt="ilustração gravidez 1" src={IlustraGravidez} />
        </div>
      </section>
      <section className="wrapper-section-row gravidez-second-section">
        <div className="item1 text-center">
          <p className="text-textSmall lg:text-titleMedium text-darkPurpleColor font-light">Quer falar sobre concepção e fertilidade com uma obstetra de confiança? Você pode fazer uma consulta presencial ou online na Theia.</p>
          <AnchorLink
            stripHash={true} 
            className="bg-darkPurpleColor mt-5 lg:mt-10 lg:mb-20 mb-5 agendar"
            onAnchorLinkClick={() => redirectButton()}
            to="https://app.theia.com.br"
          >
            Agendar uma consulta
          </AnchorLink>
        </div>
      </section>
      <section className="wrapper-section-row engravidar third relative">
        <div className="item3">
          <img alt="ilustração gravidez 2" src={IlustraGravidezSecond} />
        </div>
        <div className="item2 font-light lg:text-titleMedium text-textSmall text-darkGreyColor">
          <h2 className="item1 text-textMedium lg:text-titleLarge font-medium text-darkPurpleColor tentativas">
            Tentativas
          </h2>
          <p>É importante não tornar essa fase de tentativas uma obrigação, o que pode interferir negativamente na relação do casal. Porém, vale atentar para alguns cuidados que podem ajudar, como:</p>
          <ul>
            <li>a frequência das relações sexuais devem ser de 2 a 3 vezes na semana e se possível, todos os dias no período fértil. Vale frisar que ter relação sexual diariamente não interfere na qualidade do sêmen.</li>
            <li>evitar o uso de lubrificantes, pois podem reduzir a motilidade dos espermatozoides, dificultando a fecundação.</li>
            <li>a posição durante o ato sexual, a presença ou não de orgasmo feminino e a posição da mulher após a ejaculação masculina não interferem nas taxas de sucesso.</li>
          </ul>
          <div className="item4">
            <img alt="ilustração gravidez 3" src={IlustraGravidezThird} />
          </div>
          <h2 className="item1 text-textMedium lg:text-titleLarge font-medium text-darkPurpleColor">
            Fatores que influenciam nas chances de engravidar
          </h2>
          <p>
            A <b>idade da mulher</b> influi diretamente nas chances de gravidez, pois sua reserva ovariana diminui progressivamente ao longo da vida, tendo uma queda maior após os 35 anos. Assim, reduzindo a quantidade e qualidade de seus óvulos, as taxas de gravidez podem diminuir. A idade do homem parece não interferir nas taxas de gravidez, porém pode haver piora na qualidade do sêmen, o que reduz o número de espermatozoides e sua mobilidade, além de aumentar os riscos de alterações genéticas, neurológicas e psiquiátricas em filhos de homens com idade avançada.
          </p>
          <div className="item5">
            <img alt="ilustração gravidez 4" src={IlustraGravidezFourth} />
          </div>
          <p>
            <b>Estilo de vida</b> e fatores ambientais podem interferir diretamente na fertilidade do casal, sendo importante atentar-se a eles nesse período. O tabagismo, quando presente, deve ser interrompido por ambos, pois pode diminuir a fertilidade ao diminuir a quantidade e qualidade dos óvulos e do sêmen, além de causar prejuízos em caso de gravidez. O consumo excessivo de álcool pelo homem pode causar alterações hormonais e impotência, além de alterar a produção de espermatozoides, devendo ser consumido com moderação; já para mulher, apesar dos efeitos negativos na fertilidade serem menores, recomenda-se abstinência devido aos graves riscos de exposição fetal ao álcool desde o início da gravidez.
          </p>
          <p>
            O <b>peso</b> pode interferir diretamente na fertilidade, sendo recomendado que o casal mantenha-se com índice de massa corpórea (IMC) adequado, entre 18,5 e 25 kg/m. Na mulher, baixo peso está frequentemente associado a amenorreia e anovulação (ausência de menstruação e de ovulação, respectivamente) por exercício físico excessivo ou ingestão calórica inadequada, muitas vezes por causa de distúrbios alimentares. Já sobrepeso e obesidade têm maior associação com síndrome dos ovários policísticos, uma das principais causas de anovulação. Nos homens, apesar dessa associação não estar bem estabelecida, os benefícios do peso adequado para saúde de forma geral são amplamente conhecidos. Por isso, é importante manter atividade física e dieta adequados.
          </p>
          <div className="item6">
            <img alt="ilustração gravidez 5" src={IlustraGravidezFifith} />
          </div>
          <p>
            <b>Atividade física</b> deve ser realizada rotineiramente, porém as mulheres devem evitar exercícios físicos extenuantes por mais de 5 horas por semana, pois podem interferir negativamente na sua fertilidade. Já os homens, não precisam ter essa limitação. Em relação a alimentação, não existem fortes evidências relacionadas a uma dieta específica e alteração na fertilidade, entretanto, recomenda-se seguir uma dieta balanceada, a fim de evitar ganho de peso excessivo e deficiências nutricionais. Além disso, sugere-se que a mulher evite o consumo excessivo de cafeína, não ultrapassando o limite de 200 mg de cafeína por dia (1 ou 2 copos de café), apesar de ainda ser incerto sua interferência na fertilidade.
          </p>
          <p>
            E por último, alguns estudos demonstram que o estresse pode estar associado a infertilidade e, por sua vez, o diagnóstico e o tratamento da infertilidade podem ser muito estressantes. Portanto, é importante que o casal mantenha sua saúde física e mental, além de compartilhar suas ansiedades entre si e conversar sempre com um médico de confiança.
          </p>
          <p>
            Texto em parceria com Dra. Danielle Domingues, <a className="text-darkPurpleColor underline" href="/obstetra">ginecologista e obstetra Theia.</a>
          </p>
        </div>
      </section>
      {/* <section className="wrapper-section-row gravidez-pre-footer teste-de-gravidez">
        <div className="item10">
          <p className="text-textSmall lg:text-titleMedium text-darkPurpleColor font-light lg:mb-10">Quer falar com uma obstetra sobre suspeita de gravidez, teste e outras dúvidas? Você pode fazer uma consulta online na Theia.</p>
          <FormEngravidar className="form-gravidez" />
        </div>
        <div className="item30">
          <img src={ObstetraImg} alt="ilustração obstetra" />
        </div>
      </section> */}
    </Layout>
  )
}

export default Engravidar